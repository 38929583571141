import React from 'react';
import { GatsbyBrowser } from 'gatsby';

import { Helmet } from 'react-helmet';

import './src/styles/index.css';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          FragFrauFreitag | Antworten auf drängende Fragen des Lebens
        </title>
        <meta
          name="description"
          content="Frau Freitag gibt Antworten auf drängende Fragen des Lebens mit dem Ihr angeborenen heiligen Ernst."
        />
        <link rel="canonical" href="https://fragfraufreitag.ch" />
      </Helmet>
      {element}
    </>
  );
};
